import {graphql} from "gatsby";
import React from "react";

import Layout from "../../components/Layout";
import BlogGallery from "../../createdpages/blog/BlogGallery/BlogGallery";

const Team = ({data}) => {
  return (
    <Layout seoData={{title: "Mason | Run Remote Teams"}}>
      <div className='full-bleed layouted bg-gray-200'>
        <div className='coll-header my-24'>
          <h1 style={{color: "#8A3063"}}>Run Remote Teams</h1>
          <br />
          <h3 className='light w-full w-2/4'>
            Notes on missteps, battle-scars and open learnings from our team.
          </h3>
        </div>
        <BlogGallery data={data.allGhostPost.nodes} />
      </div>
    </Layout>
  );
};

export const query = graphql`
  {
    allGhostPost(filter: {tags: {elemMatch: {slug: {in: ["culture"]}}}}) {
      nodes {
        feature_image
        localImage {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED)
          }
        }
        featured
        id
        tags {
          slug
          name
        }
        title
        authors {
          profile_image
          name
        }
        reading_time
        slug
        html
        published_at
      }
    }
  }
`;

export default Team;
